body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  min-height: 100dvh;
  overflow-x: hidden;
  /* min-height: calc(var(--vh, 1vh) * 100); */
  /* touch-action: pan-x pan-y; */
}

#root {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  /* min-height: calc(var(--vh, 1vh) * 100); */
}
