:root {
  --color-lightlightblue: #f0f6f8;
  --color-lightblue: #6ac3e1;
  --color-blue: #1fb0e1;
  --color-darkblue: #141b41;
  --color-green: #21ad6d;
  --color-darkgreen: #0e4d30;
  --color-transparent: transparent;
  --color-lightlightred: #fd8a8a;
  --color-lightred: #ff5a5a;
  --color-red: red;
  --color-white: #ffffff;
  /* background color */
  --color-lightlightgray: #fbfbfd;
  --color-lightlightgray2: rgb(230, 230, 230);
  --color-lightgray: #f7f7f7;
  --color-gray: lightgray;
  --color-darkgray: gray;
  --color-black: #000000;
  --color-gray-12: rgb(32, 32, 32);
  --color-gray-24: rgb(64, 64, 64);
  --color-gray-36: rgb(96, 96, 96);
  --color-gray-48: rgb(128, 128, 128);
  --color-gray-60: rgb(160, 160, 160);
  --color-gray-72: rgb(192, 192, 192);
  --color-gray-84: rgb(224, 224, 224);
  --bs-nav-link-font-size: 1.1rem;
  --bs-dropdown-font-size: 1.1rem;
}

a {
  color: var(--color-darkblue);
}

.clickable {
  cursor: pointer;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.btn-modal,
.btn-modal:active {
  background-color: white !important;
  border-color: lightgray !important;
  color: black !important;
  border-radius: 50px !important;
}

.btn-modal:hover {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: black !important;
}
.btn-modal.disabled,
.btn-modal:disabled {
  background-color: white !important;
  border-color: lightgray !important;
  color: lightgray !important;
}

/* white/lightgray background */
.btn-green,
.btn-green:active {
  background-color: var(--color-green) !important;
  border-color: var(--color-green) !important;
  color: var(--color-white) !important;
  border-radius: 50px !important;
}

.btn-green:hover {
  color: var(--color-white) !important;
  background-color: var(--color-darkgreen) !important;
  border-color: var(--color-darkgreen) !important;
}
.btn-green.disabled,
.btn-green:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-green) !important;
  border-color: var(--color-green) !important;
}

/* white/lightgray background */
.btn-darkblue,
.btn-darkblue:active {
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
  color: var(--color-white) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
}

.btn-darkblue:hover {
  color: var(--color-white) !important;
  background-color: var(--color-black) !important;
  border-color: var(--color-black) !important;
}
.btn-darkblue.disabled,
.btn-darkblue:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-gray) !important;
  border-color: var(--color-gray) !important;
}

/* white/lightgray background */
.btn-black,
.btn-black:active {
  background-color: var(--color-black) !important;
  border-color: var(--color-black) !important;
  color: var(--color-white) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
}

.btn-black:hover {
  color: var(--color-white) !important;
  background-color: var(--color-black) !important;
  border-color: var(--color-black) !important;
}
.btn-black.disabled,
.btn-black:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-gray) !important;
  border-color: var(--color-gray) !important;
}

/* white/lightgray background */
.btn-blue,
.btn-blue:active {
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
  color: var(--color-white) !important;
  border-radius: 50px !important;
}

.btn-blue:hover {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}
.btn-blue.disabled,
.btn-blue:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
}

/* white/lightgray background */
.btn-gray,
.btn-gray:active {
  background-color: var(--color-darkgray) !important;
  border-color: var(--color-darkgray) !important;
  color: var(--color-white) !important;
  border-radius: 50px !important;
}

.btn-gray:hover {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}
.btn-gray.disabled,
.btn-gray:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}

/* white/lightgray background */
.btn-white,
.btn-white:active {
  background-color: var(--color-white) !important;
  border-color: var(--color-darkblue) !important;
  color: var(--color-darkblue) !important;
  border-radius: 50px !important;
}

.btn-white:hover {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}
.btn-white.disabled,
.btn-white:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}

/* primary: blue on white background */
.btn-primary,
.btn-primary:active {
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}

.btn-primary:hover {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}

/* primary-danger: red on white background */
.btn-primary-danger,
.btn-primary-danger:active {
  background-color: var(--color-lightred) !important;
  border-color: var(--color-lightred) !important;
  color: var(--color-white) !important;
  border-radius: 50px !important;
}

.btn-primary-danger:hover {
  color: #ffffff !important;
  background-color: var(--color-red) !important;
  background-color: var(--color-red) !important;
}
.btn-primary-danger.disabled,
.btn-primary-danger:disabled {
  color: var(--color-white) !important;
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
}

/* primary-warning: red on white background */
.btn-primary-warning,
.btn-primary-warning:active {
  background-color: #ffce3b !important;
  border-color: #ffce3b !important;
  color: var(--color-black) !important;
}

.btn-primary-warning:hover {
  color: var(--color-black) !important;
  background-color: #ffbf00 !important;
  background-color: #ffbf00 !important;
}
.btn-primary-warning.disabled,
.btn-primary-warning:disabled {
  color: var(--color-darkgray) !important;
  background-color: #fce18d !important;
  border-color: #fce18d !important;
}

/* tertiary: blue background */
.btn-tertiary,
.btn-tertiary:active {
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
  color: var(--color-blue) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
}

.btn-tertiary:hover {
  color: var(--color-white) !important;
  background-color: var(--color-black) !important;
  border-color: var(--color-black) !important;
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: var(--color-blue) !important;
  background-color: var(--color-lightblue) !important;
  border-color: var(--color-lightblue) !important;
}

/* quaternary: blue background */
.btn-quaternary,
.btn-quaternary:active {
  background-color: var(--color-darkblue) !important;
  border-color: var(--color-darkblue) !important;
  color: var(--color-blue) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
}

.btn-quaternary:hover {
  color: var(--color-white) !important;
  background-color: var(--color-black) !important;
  border-color: var(--color-black) !important;
}
.btn-quaternary.disabled,
.btn-quaternary:disabled {
  color: var(--color-blue) !important;
  background-color: var(--color-lightblue) !important;
  border-color: var(--color-lightblue) !important;
}

.section {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.privacypolicy_termsofservice h2 {
  margin-top: 1em;
}

.privacypolicy_termsofservice h3 {
  margin-top: 1em;
}

.privacypolicy_termsofservice ul {
  margin-bottom: 1em;
}

.privacypolicy_termsofservice p,
.privacypolicy_termsofservice li {
  line-height: 1em;
}

.privacypolicy_termsofservice ul,
.privacypolicy_termsofservice h1,
.privacypolicy_termsofservice h2,
.privacypolicy_termsofservice h3 {
  line-height: 1em;
}

.thumb {
  border-radius: 10px;
}

.page {
  font-family: "Jost", sans-serif;
  margin-top: 88px;
}

.section-title {
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 700 !important;
}

.section-description {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400 !important;
}

.step-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-number-arrow::before {
  content: "";
}

.step-number {
  font-size: 2rem;
  background-color: var(--color-blue);
  border-radius: 100px;
  color: var(--color-white);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-title {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 500 !important;
}

.step-description {
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400 !important;
}

.navbar {
  --bs-navbar-nav-link-padding-x: 1.2rem !important;
}

.navbar-toggler {
  align-self: auto;
}

.menu {
  line-height: 25px;
  font-family: "Jost", sans-serif;
  border-bottom: 1px solid #e6e6e6;
  background-color: var(--color-white) !important;
}

/* 
#navbarScroll {
  background-color: var(--color-white);
} */

.main-banner {
  background-image: url(../assets/header.jpg);
  display: flex;
  align-items: center;
  min-height: calc(100vh - 100px);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.main-banner-overlay {
  background-color: var(--color-white);
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.dropdown-toggle::after {
  vertical-align: 0 !important;
}

.btn-plus-ctn {
  position: fixed;
  right: 0;
  bottom: 0;
}

.btn-unregistereduser-ctn {
  position: fixed;
  right: 64px;
}

.border-top {
  border-top: 1px solid var(--color-gray);
}

.border-bottom {
  border-bottom: 1px solid var(--color-gray);
}

.album-menu-item {
  color: var(--color-black);
  background-color: var(--color-white);
}

.album-menu-item:hover {
  background-color: var(--color-lightgray);
}

.settings-menu-item {
  height: 50px;
  color: var(--color-black);
  background-color: var(--color-white);
}

.settings-menu-item:hover {
  background-color: var(--color-lightgray);
}

.active {
  background-color: var(--color-lightlightblue) !important;
}

.settings-menu-item2 {
  color: var(--color-black);
  background-color: var(--color-white);
}

.settings-menu-item2:hover {
  background-color: var(--color-lightgray);
}

.settings-menu-headeritem {
  height: 50px;
  padding-left: 10px;
  background-color: var(--color-lightlightgray2);
  color: var(--color-black);
  font-weight: bold;
}

.package {
  border: 1px solid #ddd;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.cover {
  display: inline-flex;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.albums > div {
  border-bottom: 1px solid var(--color-lightgray);
}

.albums:first-child {
  border-top: 1px solid var(--color-lightgray);
}

@media screen and (min-width: 992px) {
  .step-number-arrow::before {
    content: "\e96b";
    font-family: Moririse2 !important;
    position: absolute;
    font-size: 32px;
    left: -30px;
    color: #1fb0e1;
  }
}

.left-0 {
  left: 0;
}

.left-5 {
  left: 5px;
}

.left-10 {
  left: 10px;
}

.top-0 {
  top: 0;
}

.top-5 {
  top: 5px;
}

.top-10 {
  top: 10px;
}

.right-0 {
  right: 0;
}

.right-5 {
  right: 5px;
}

.right-10 {
  right: 10px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 5px;
}

.bottom-10 {
  bottom: 10px;
}

.font-white {
  color: var(--color-white) !important;
}

.font-darkblue {
  color: var(--color-darkblue) !important;
}

.font-black {
  color: var(--color-black);
}

.modal-body-height {
  max-height: calc(100dvh - 200px);
  overflow-y: auto;
  /* overscroll-behavior: none; */
}

@media screen and (max-width: 575px) {
  .modal-body-height {
    max-height: calc(100dvh - 160px);
    overflow-y: auto;
  }
}

.dropdown-item:active {
  background-color: var(--color-darkblue) !important;
}

.Calendar {
  width: 100% !important;
  max-width: 330px !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--color-white), var(--bs-bg-opacity)) !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "" !important;
}

.swiper-button-prev {
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2026.3.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%20512%20512%22%20style%3D%22enable-background%3Anew%200%200%20512%20512%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Ccircle%20cx%3D%22256%22%20cy%3D%22256%22%20r%3D%22256%22%2F%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M271%2C135c9.3999939-9.4000015%2C24.6000061-9.4000015%2C33.8999939%2C0s9.3999939%2C24.6000061%2C0%2C33.8999939l-87%2C87%0A%09l87%2C87c9.3999939%2C9.3999939%2C9.3999939%2C24.6000061%2C0%2C33.8999939s-24.6000061%2C9.3999939-33.8999939%2C0L167%2C273%0A%09c-9.3999939-9.3999939-9.3999939-24.6000061%2C0-33.8999939L271%2C135z%22%2F%3E%0A%3C%2Fsvg%3E%0A") !important;
}

.swiper-button-next {
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2026.3.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%20512%20512%22%20style%3D%22enable-background%3Anew%200%200%20512%20512%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Ccircle%20cx%3D%22256%22%20cy%3D%22256%22%20r%3D%22256%22%2F%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M241%2C377c-9.3999939%2C9.3999939-24.6000061%2C9.3999939-33.8999939%2C0s-9.3999939-24.6000061%2C0-33.8999939l87-87%0A%09l-87-87c-9.3999939-9.3999939-9.3999939-24.6000061%2C0-33.8999939s24.6000061-9.4000015%2C33.8999939%2C0L345%2C239%0A%09c9.3999939%2C9.3999939%2C9.3999939%2C24.6000061%2C0%2C33.8999939L241%2C377z%22%2F%3E%0A%3C%2Fsvg%3E%0A") !important;
}

.inviteqrcode {
  max-height: 256px;
}

@media screen and (max-height: 768px) {
  .inviteqrcode {
    max-height: 200px;
  }
}

@media screen and (max-height: 650px) {
  .inviteqrcode {
    max-height: 150px;
  }
}

.addtohomescreen-control ol:first-of-type,
.addtohomescreen-control ul:first-of-type {
  margin-bottom: 20px;
}

.addtohomescreen-control ol {
  counter-reset: ol-counter;
}

.addtohomescreen-control ol,
.addtohomescreen-control ul {
  list-style: none;
  padding: 0;
  list-style-type: none;
}

.addtohomescreen-control ol li {
  padding-left: 40px;
  position: relative;
}

.addtohomescreen-control ol li:before {
  text-align: center;
  color: #fefefe;
  line-height: 1;
  content: counter(ol-counter, decimal);
  counter-increment: ol-counter;
  background: var(--color-blue);
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-album .album-cover-img {
  width: 120px;
  height: 120px;
}
a.noUnderline {
  text-decoration: none;
}

.code-control .code-seperator {
  width: 10px;
  height: 5px;
}

.code-control .code-control-digit-container {
  background-color: white;
  display: flex;
  border-radius: 5;
  justify-content: center;
  align-items: center;
}

.code-control .code-control-digit-text {
  display: inline;
}

.code-control-4 .code-control-digit-container {
  width: 65px;
  height: 65px;
}

.code-control-4 .code-control-digit-text {
  font-size: 40px;
  font-weight: bold;
  color: black;
}

.code-control-6 .code-control-digit-container {
  width: 45px;
  height: 55px;
}

.code-control-6 .code-control-digit-text {
  font-size: 32px;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 380px) {
  .code-control .code-seperator {
    width: 0px;
    height: 0px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-image {
  animation: fadeIn 5s;
}

.fade-out-image {
  animation: fadeOut 5s;
}

.social {
  color: white;
}

.btn-toolbar .btn {
  margin-right: 5px;
}
.btn-toolbar .btn:last-child {
  margin-right: 0;
}
